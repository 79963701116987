import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
// Components
import PatreonButton from './PatreonButton'
// Styles
import * as styles from './index.module.css'

const __PATREON_TIERS__ = [
    {
        title: 'Kanden⚡',
        price: '$2',
        benefits: [
            'Acceso a borradores de traducciones',
            'Traducciones y contenido exclusivo',
        ],
    },
    {
        title: 'Lemon🍋',
        price: '$5',
        benefits: ['Pedidos de traducción', 'Usuario destacado'],
    },
]

type PatreonWidgetProps = {}

const PatreonWidget: React.FC<PatreonWidgetProps> = ({}) => {
    return (
        <>
            <div className={styles.patreon_widget}>
                <header className={styles.patreon_header}>
                    <StaticImage
                        className={styles.patreon_header__cover}
                        src="./Portal.webp"
                        alt="Portada Patreon"
                    />
                    <StaticImage
                        className={styles.patreon_header__pfp}
                        src="./Logo.webp"
                        alt="Perfil Patreon"
                    />
                </header>
                <div>
                    <h2 className={styles.patreon_name}>
                        Portal KenshiYonezu.net
                    </h2>
                    {__PATREON_TIERS__.map((tier) => (
                        <section
                            key={tier.title}
                            className={styles.patreon_tier}
                        >
                            <h3 className={styles.patreon_tier_name}>
                                <span>{tier.title}</span>{' '}
                                <span className={styles.patreon_tier__price}>
                                    {tier.price}
                                </span>
                            </h3>
                            <ul className={styles.patreon_benefits}>
                                {tier.benefits.map((benefit) => (
                                    <li key={benefit}>{benefit}</li>
                                ))}
                            </ul>
                        </section>
                    ))}
                </div>
                <footer>
                    <PatreonButton />
                </footer>
            </div>
        </>
    )
}

export default PatreonWidget
