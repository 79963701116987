import React from 'react'
// Components
import Content from 'components/Layout/Content'
import DefaultSidebar from 'components/Layout/Sidebar/DefaultSidebar'
import UsersList from './UsersList'
import PageNav from 'components/UIElements/Navigation/PageNav'
// Models
import { IContextWithPageNav } from 'models/Context'
import { IUserCard } from 'models/User'
// Styles
import * as styles from './UsersContainer.module.css'

type UsersContainerProps = {
    users: IUserCard[]
    context: IContextWithPageNav
}

const UsersContainer: React.FC<UsersContainerProps> = ({ users, context }) => {
    const { currentPage, totalPages } = context
    return (
        <section className={styles.users}>
            <Content>
                <UsersList users={users} />
                <PageNav
                    to="/users"
                    currentPage={currentPage}
                    totalPages={totalPages}
                />
            </Content>
            <DefaultSidebar />
        </section>
    )
}

export default UsersContainer
