import React from 'react'
import PageTitle from 'components/UIElements/PageTitle'
// Styles
import * as styles from './index.module.css'

type SidebarSectionProps = {
    title: string
    className?: string
}

const Sidebar: React.FC = ({ children }) => {
    return <aside className={styles.sidebar}>{children}</aside>
}

export const SidebarSection: React.FC<SidebarSectionProps> = ({
    title,
    className,
    children,
}) => {
    return (
        <section className={styles.sidebar_section}>
            <PageTitle title={title} />
            {className ? (
                <div className={className}>{children}</div>
            ) : (
                <div>{children}</div>
            )}
        </section>
    )
}

export default Sidebar
