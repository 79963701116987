import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Components
import Layout from 'components/Layout'
import SectionHeader from 'components/Layout/SectionHeader'
// Models
import { IOptionalMetaProps, ISeoQuery } from 'models/SEO'
import { IUserCard } from 'models/User'
import UsersContainer from 'components/Sections/Users/UsersContainer'
import { IContextWithPageNav } from 'models/Context'

type UsersListPageProps = {
    users: {
        nodes: IUserCard[]
    }
    pageSeo: ISeoQuery
}

const UsersListPage: React.FC<
    PageProps<UsersListPageProps, IContextWithPageNav>
> = ({ data, pageContext: context }) => {
    const users = data.users.nodes
    const pageSeo = data.pageSeo
    const seo: IOptionalMetaProps = {
        title: pageSeo.title,
        description: pageSeo.description.text,
        image: pageSeo.image.file?.url,
    }
    return (
        <Layout seo={seo}>
            <SectionHeader title={pageSeo.title} image={pageSeo.image} />
            <UsersContainer context={context} users={users} />
        </Layout>
    )
}

export default UsersListPage

export const pageQuery = graphql`
    query UsersListPage($limit: Int!, $skip: Int!) {
        users: allContentfulUsers(limit: $limit, skip: $skip) {
            nodes {
                name
                username

                avatar {
                    gatsbyImageData(width: 130)
                    file {
                        url
                    }
                }
                cover {
                    gatsbyImageData(width: 550)
                }
            }
        }

        pageSeo: contentfulSeo(slug: { eq: "users" }) {
            title
            description {
                text: description
            }
            image {
                gatsbyImageData
                file {
                    url
                }
            }
        }
    }
`
