import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
// Models
import { IProfileCard } from 'models/Profile'
// Styles
import * as styles from './ProfileCard.module.css'

const ProfileCard: React.FC<IProfileCard> = ({
    name,
    subname,
    to,
    avatar,
    cover,
}) => {
    return (
        <Link className={styles.profile_card} aria-label={name} to={to}>
            <figure className={styles.profile_card_header}>
                <GatsbyImage
                    className={styles.profile_card_cover}
                    image={cover.gatsbyImageData}
                    alt={name}
                />
                <GatsbyImage
                    className={styles.profile_card_avatar}
                    image={avatar.gatsbyImageData}
                    alt={name}
                />
            </figure>
            <div className={styles.profile_card_info}>
                <h2 className={styles.profile_card_name}>{name}</h2>
                <h3 className={styles.profile_card_subname}>{subname}</h3>
            </div>
        </Link>
    )
}

export default ProfileCard
