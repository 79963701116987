// extracted by mini-css-extract-plugin
export var patreon_widget = "index-module--patreon_widget--pSfN5";
export var patreon_header = "index-module--patreon_header--uj2fU";
export var patreon_header__cover = "index-module--patreon_header__cover--1SxPj";
export var patreon_header__pfp = "index-module--patreon_header__pfp--9g79y";
export var patreon_name = "index-module--patreon_name--ZqgdK";
export var patreon_tier = "index-module--patreon_tier--dDW4L";
export var patreon_tier_name = "index-module--patreon_tier_name--FPFHe";
export var patreon_tier__price = "index-module--patreon_tier__price--bgTNW";
export var patreon_benefits = "index-module--patreon_benefits--1+ghO";
export var patreon_footer__button = "index-module--patreon_footer__button--gZqVu";