import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
// Models
import { IImage } from 'models/Image'
// Styles
import * as styles from './SectionHeader.module.css'

type SectionHeaderProps = {
    image: IImage
    title: string
}

const SectionHeader: React.FC<SectionHeaderProps> = ({ image, title }) => {
    return (
        <header className={styles.section_header}>
            <GatsbyImage
                className={styles.section_background}
                image={image.gatsbyImageData}
                alt={title}
                loading="eager"
            />
            <h1 className={styles.section_title}>{title}</h1>
        </header>
    )
}

export default SectionHeader
