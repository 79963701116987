import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// Styles
import * as styles from './PatreonButton.module.css'

const __PATREON_URL__ = 'https://www.patreon.com/bePatron?u=67654458'
type PatreonButtonProps = {
    text?: string
}

const PatreonButton: React.FC<PatreonButtonProps> = ({
    text = '¡Súmate a Patreon!',
}) => {
    return (
        <>
            <a
                className={styles.patreon_button}
                href={__PATREON_URL__}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Patreon"
                data-social="patreon"
            >
                <FontAwesomeIcon
                    className={styles.patreon_button_icon}
                    icon={['fab', 'patreon']}
                />
                {text}
            </a>
        </>
    )
}

export default PatreonButton
