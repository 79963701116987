import React from 'react'
// Components
import ProfileCard from 'components/UIElements/Card/ProfileCard'
// Models
import { IUserCard } from 'models/User'
import { IProfileCard } from 'models/Profile'
// Styles
import * as styles from './UsersList.module.css'

type UsersListProps = {
    users: IUserCard[]
}

const UsersList: React.FC<UsersListProps> = ({ users }) => {
    return (
        <div className={styles.users_list}>
            {users.map((user) => {
                const profileCardProps: IProfileCard = {
                    name: user.name,
                    subname: `@${user.username}`,
                    avatar: user.avatar,
                    cover: user.cover,
                    to: `/users/${user.username}`,
                }
                // <UserCard user={user} />
                return <ProfileCard {...profileCardProps} />
            })}
        </div>
    )
}

export default UsersList
