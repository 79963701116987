import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useStaticQuery, graphql } from 'gatsby'
// Components
import Sidebar, { SidebarSection } from 'components/Layout/Sidebar'
import PatreonWidget from 'components/UIElements/Patreon'
// Models
import { ISite } from 'models/SEO'
// Styles
import * as styles from './DefaultSidebar.module.css'

type DefaultSidebarProps = {}

const DefaultSidebar: React.FC<DefaultSidebarProps> = ({}) => {
    const { site } = useStaticQuery(query) as ISite
    const { twitter, facebook, instagram, youtube, telegram } =
        site.siteMetadata
    return (
        <Sidebar>
            <SidebarSection title="Estamos en">
                <div className={styles.social_panel}>
                    <a
                        href={`https://www.twitter.com/${twitter}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Síguenos en Twitter"
                        data-social="twitter"
                        className={styles.social_button}
                    >
                        <FontAwesomeIcon
                            className={styles.social_icon}
                            icon={['fab', 'twitter']}
                        />
                        Twitter
                    </a>
                    <a
                        href={`https://www.instagram.com/${instagram}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Síguenos en Instagram"
                        data-social="instagram"
                        className={styles.social_button}
                    >
                        <FontAwesomeIcon
                            className={styles.social_icon}
                            icon={['fab', 'instagram']}
                        />
                        Instagram
                    </a>
                    <a
                        href={`https://fb.me/${facebook}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Síguenos en Facebook"
                        data-social="facebook"
                        className={styles.social_button}
                    >
                        <FontAwesomeIcon
                            className={styles.social_icon}
                            icon={['fab', 'facebook']}
                        />
                        Facebook
                    </a>
                    {/* <a
                        href={`https://www.youtube.com/${youtube}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Síguenos en Youtube"
                        data-social="youtube"
                        className={styles.social_button}
                    >
                        <FontAwesomeIcon
                            className={styles.social_icon}
                            icon={['fab', 'youtube']}
                        />
                        Youtube
                    </a>
                    <a
                        href={`https://telegram.me/${telegram}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Síguenos en nuestro canal de Telegram"
                        data-social="telegram"
                        className={styles.social_button}
                    >
                        <FontAwesomeIcon
                            className={styles.social_icon}
                            icon={['fab', 'telegram']}
                        />
                        Telegram
                    </a> */}
                </div>
            </SidebarSection>
            {/* <SidebarSection title="Twitter">
                <a
                    className="twitter-timeline"
                    data-height="600"
                    data-theme="dark"
                    href="https://twitter.com/KenshiNet?ref_src=twsrc%5Etfw"
                >
                    @KenshiNet en Twitter
                </a>
            </SidebarSection> */}
            <SidebarSection title="¡Apoyanos!">
                <PatreonWidget />
            </SidebarSection>
        </Sidebar>
    )
}

export default DefaultSidebar

const query = graphql`
    query {
        site {
            siteMetadata {
                site_name
                description
                siteUrl
                twitter
                instagram
                facebook
                keywords
                image
            }
        }
    }
`
