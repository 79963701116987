import React from 'react'
import { Link } from 'gatsby'
// Styles
import * as styles from './PageNav.module.css'

type PageNavProps = {
    to: string
    currentPage: number
    totalPages: number
}

const PageNav: React.FC<PageNavProps> = ({ to, currentPage, totalPages }) => {
    const __NEXT_PAGE__ = 'Siguiente'
    const __PREVIOUS_PAGE__ = 'Anterior'
    if (!to || (!currentPage && !totalPages)) return <></>
    return (
        <>
            <hr />
            <nav className={styles.page_navigation}>
                {currentPage === 1 ? (
                    <span className={styles.nav_button}>
                        {__PREVIOUS_PAGE__}
                    </span>
                ) : (
                    <Link
                        to={`${to}/${
                            currentPage === 2 ? `` : `${currentPage - 1}`
                        }`}
                        className={styles.nav_button}
                    >
                        {__PREVIOUS_PAGE__}
                    </Link>
                )}
                {currentPage === totalPages ? (
                    <span className={styles.nav_button}>{__NEXT_PAGE__}</span>
                ) : (
                    <Link
                        className={styles.nav_button}
                        to={`${to}/${currentPage + 1}`}
                    >
                        {__NEXT_PAGE__}
                    </Link>
                )}
            </nav>
        </>
    )
}

export default PageNav
