import React from 'react'
// Styles
import * as styles from './Content.module.css'

const Content: React.FC<{ className?: string }> = ({ children, className }) => {
    if (className) {
        return (
            <div className={`${styles.content} ${className}`}>{children}</div>
        )
    }
    return <div className={styles.content}>{children}</div>
}

export default Content
