import React from 'react'
// Stlyes
import * as styles from './index.module.css'

const PageTitle: React.FC<{ title: string }> = ({ title }) => {
    return (
        <div className={styles.page_section}>
            <h2 className={styles.page_section_title}>{title}</h2>
        </div>
    )
}

export default PageTitle
